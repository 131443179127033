<template>
  <v-container>
    <v-responsive>
      <h1 class="ml-2 mb-5 mt-5">Planbestellung</h1>
      <v-row class="ma-2">

        <div class="col-sm-9 custom-col-main">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <div v-for="(order, index) in orders" :key="index">
                <v-row align="left">
                  <!-- Community Selection -->
                  <v-col cols="12" class="mt-2">
                    <h3>Geodaten Auswahl</h3>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      class="ma-2"
                      v-model="order.community"
                      :items="communities"
                      item-value="id"
                      item-title="name"
                      dense
                      required
                      label="Wählen Sie eine Gemeinde"
                    />
                  </v-col>

                  <!-- Products Table -->
                  <v-simple-table class="media-table" v-if="order.community">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Produkt</th>
                          <th class="text-left">Papierplan</th>
                          <th class="text-left">PDF</th>
                          <th class="text-left">Digitale Daten (DXF/DWG)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(productGroup, i) in groupedProducts"
                          :key="i"
                        >
                          <td>{{ productGroup.name }}</td>
                          <td v-if="productGroup.paper">
                            {{ productGroup.paper.name }} - Preis:
                            {{ productGroup.paper.price }}
                            <span class="otherformats-small">
                              {{ productGroup.paper.otherformats }}
                            </span>
                          </td>
                          <td v-if="productGroup.pdf">
                            {{ productGroup.pdf.name }} - Preis:
                            {{ productGroup.pdf.price }}
                            <span class="otherformats-small">
                              {{ productGroup.pdf.otherformats }}
                            </span>
                            <v-checkbox v-model="productGroup.pdf.selected" />
                          </td>
                          <td v-if="productGroup.dxf">
                            {{ productGroup.dxf.name }} - Preis:
                            {{ productGroup.dxf.price }}
                            <v-checkbox v-model="productGroup.dxf.selected" />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <!-- Media and Data Format Selection -->
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="order.media"
                      :items="media"
                      :disabled="!order.community"
                      dense
                      filled
                      label="Wählen Sie die Daten"
                    />
                  </v-col>

                  <v-col
                    v-if="order.media === 'Amtliche Vermessung'"
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="order.dataFormat"
                      :items="dataFormatAv"
                      multiple
                      dense
                      filled
                      label="Wählen Sie das Datenformat für die Amtliche Vermessung"
                    />
                  </v-col>

                  <v-col v-if="order.media === 'Werkleitungen'" cols="12">
                    <v-autocomplete
                      v-model="order.dataFormat"
                      :items="dataFormatWl"
                      multiple
                      dense
                      filled
                      label="Wählen Sie das Datenformat für die Werkleitungen"
                    />
                  </v-col>

                  <!-- Address and Parcel -->
                  <v-col cols="12">
                    <h3>Ausschnitt</h3>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="order.orderAddress"
                      label="Adresse"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="order.orderParcel"
                      label="Parzellennummer"
                      required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      label="Beispiel Ausschnitt"
                      @change="handleFileUpload"
                    />
                    <small>
                      *Wenn eingefügt, müssen nicht alle Adressen eingegeben
                      werden.
                    </small>
                  </v-col>
                  <v-btn
                    @click="removeOrder(index)"
                    color="rgba(189,16,32)"
                    class="ma-2"
                  >
                    Entfernen
                  </v-btn>
                </v-row>
              </div>

              <v-btn @click="addOrder" class="ma-2" color="rgba(32,16,189)">
                Weitere Geodaten Auswahl hinzufügen
              </v-btn>

              <!-- Billing Address -->
              <v-row>
                <v-col>
                  <h3>Rechnungsadresse</h3>
                </v-col>
                <v-container fluid>
                  <v-checkbox
                    v-model="mainOrder.useDifferentAddress"
                    label="Separate Lieferadresse"
                  />
                  <v-text-field
                    label="Firmenname"
                    v-model="mainOrder.companyName"
                    required
                  />
                  <v-text-field
                    label="Vor- und Nachname"
                    v-model="mainOrder.name"
                    required
                  />
                  <v-text-field
                    label="Adresszusatz"
                    v-model="mainOrder.addressAddition"
                  />
                  <v-text-field
                    label="Strasse und Nummer"
                    v-model="mainOrder.address"
                    required
                  />
                  <v-text-field
                    label="Postleitzahl"
                    v-model="mainOrder.postcode"
                    required
                  />
                  <v-text-field
                    label="Ort"
                    v-model="mainOrder.place"
                    required
                  />
                  <v-text-field
                    label="Telefonnummer"
                    v-model="mainOrder.phoneNumber"
                    required
                  />
                  <v-text-field
                    label="E-Mail"
                    v-model="mainOrder.mail"
                    required
                  />
                  <v-text-field
                    label="Bemerkungen zur Bestellung oder Versand"
                    v-model="mainOrder.comments"
                  />
                </v-container>
              </v-row>

              <v-btn
                @click="submitOrder"
                class="ma-2"
                color="rgba(189,16,32)"
              >
                Senden
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-sm-3 custom-col-menu">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <h3>Zusammenfassung</h3>

                </v-card-text>
          </v-card>    
        </div>

      </v-row>
    </v-responsive>
  </v-container>
</template>


<script>

import axios from "axios";

export default {
  name: "OrderCreate",
  data() {
    return {
      communities: [],
      media: [],
      dataFormatAv: [],
      dataFormatWl: [],


      orders: [
        {
          community: null,
          media: "",
          dataFormat: [],
          orderAddress: "",
          orderParcel: "",
          checkedArray: [],
        },
      ],
      mainOrder: {
        companyName: "",
        name: "",
        addressAddition: "",
        address: "",
        postcode: "",
        place: "",
        phoneNumber: "",
        mail: "",
        comments: "",
      },
      checkbox: false,
      selectedProducts: [],
    };
  },
  computed: {
    groupedProducts() {
      const grouped = {};

      this.selectedProducts.forEach(task => {
        if (!grouped[task.name]) {
            grouped[task.name] = { name : task.name, paper: null, pdf: null, dxf: null };
          }
       task.description.forEach(product => {
          if (product.type === 'paper') {
            grouped[task.name].paper = product;
          } else if (product.type === 'pdf') {
            grouped[task.name].pdf = product;
          } else if (product.type === 'dxf') {
            grouped[task.name].dxf = product;

          }
        });
      });
      
      const taskOrder = [
        'Auszug Plan für das Grundbuch', 
        'Katasterplan Amtliche Vermessung', 
        'Katasterplan Abwasser', 'Katasterplan Wasser', 
        'Katasterplan Fernwärme', 'Katasterplan Gas'
      ];
      return Object.values(grouped).sort((c, d) => {
        return taskOrder.indexOf(c.name) - taskOrder.indexOf(d.name);
    });
    },

  },
  watch: {
    orders: {
      handler(newOrders) {
        newOrders.forEach((newOrder) => {
          if (newOrder.community) {
            this.getServiceByCommune(newOrder.community);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.getCommunities();
  },
  methods: {
    addOrder() {
      this.orders.push({
        community: "",
        media: "",
        dataFormat: [],
        orderAddress: "",
        orderParcel: "",
        checkedArray: [],
      });
    },
    removeOrder(index) {
      this.orders.splice(index, 1);
    },
    async submitOrder() {
      try {
        const payload = {
          orders: this.orders,
          mainOrder: this.mainOrder,
        };
        await axios.post(`${process.env.VUE_APP_API_BASE_URL}/order`, payload);
        this.resetForm();
      } catch (error) {
        console.error("Error submitting order:", error);
      }
    },
    resetForm() {
      this.orders = [
        {
          community: "",
          media: "",
          dataFormat: [],
          orderAddress: "",
          orderParcel: "",
          checkedArray: [],
        },
      ];
      this.mainOrder = {
        name: "",
        addressAddition: "",
        address: "",
        postcode: "",
        place: "",
        phoneNumber: "",
        mail: "",
        comments: "", 
      };
      this.checkbox = false;
    },
    getCommunities() {
      axios
        .get(`${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/municipality`)
        .then(result => {
          this.communities = result.data;
        });
    },
    getServiceByCommune(id) {
      axios
        .get(`${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/tasks/${id}`)
        .then(result => {
          const { products } = result.data;
          this.selectedProducts = products; 
        })
        .catch(error => {
          console.error("Error fetching services:", error);
        });
    },
  },
};

</script>
