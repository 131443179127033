<template>
  <v-container>
    <v-responsive>
      <h1 class="ml-2 mb-5 mt-5">Adressänderung</h1>
      <v-row widt>
        <v-col cols="12" md="8">
          <v-card elevation="1" outlined tile class="pa-2">
            <v-card-text>
              <v-form ref="form" @submit.prevent="sendForm">
                <!-- Gemeinde Selection -->
                <v-select
                  v-model="formData.community"
                  :items="communities"
                  item-value="id"
                  item-title="name"
                  dense
                  label="Wählen Sie eine Gemeinde"
                  required
                ></v-select>

                <!-- Parcel Number -->
                <v-text-field
                  v-model="formData.parcel"
                  label="Grundstücknummer"
                  required
                ></v-text-field>

                <v-divider class="my-4"></v-divider>

                <!-- New Address Details -->
                <h2>Angaben zur neuen Adresse</h2>

                <v-text-field
                  v-model="formData.first_name"
                  label="Vorname"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.last_name"
                  label="Nachname"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.street"
                  label="Strasse"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.house_number"
                  label="Hausnummer"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.postal_code"
                  label="PLZ"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.city"
                  label="Ortschaft"
                  required
                ></v-text-field>

                <v-textarea
                  v-model="formData.remarks"
                  label="Bemerkungen"
                ></v-textarea>

                <v-divider class="my-4"></v-divider>

                <!-- Contact Information -->
                <h2>Kontaktangaben für Rückfragen</h2>

                <v-text-field
                  v-model="formData.contact_info.name"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.contact_info.first_name"
                  label="Vorname"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="formData.contact_info.email"
                  label="Email"
                  required
                  type="email"
                ></v-text-field>

                <v-text-field
                  v-model="formData.contact_info.phone"
                  label="Telefon"
                  required
                  type="tel"
                ></v-text-field>

                <!-- Submit Button -->
                <v-btn color="primary" type="submit">Senden</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-responsive>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "AddressMutation",
  data() {
    return {
      communities: [],
      formData: {
        community: "",
        parcel: "",
        first_name: "",
        last_name: "",
        street: "",
        house_number: "",
        postal_code: "",
        city: "",
        remarks: "",
        contact_info: {
          name: "",
          first_name: "",
          email: "",
          phone: "",
        },
      },
    };
  },
  mounted() {
    this.fetchCommunities();
  },
  methods: {
    async sendForm() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/address-mutation`,
          this.formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Success response handling
        console.log(response.data);
        alert("Formular wurde erfolgreich gesendet!");
        this.resetForm();
      } catch (error) {
        // Error handling
        console.error("Error:", error);
        alert("Fehler beim Senden des Formulars. Bitte versuchen Sie es erneut.");
      }
    },
    async fetchCommunities() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_ZKL_BACKEND_URL}/v1/services/municipality`
        );
        this.communities = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Gemeinden:", error);
      }
    },
    resetForm() {
      // Reset form data after successful submission
      this.formData = {
        community: "",
        parcel: "",
        first_name: "",
        last_name: "",
        street: "",
        house_number: "",
        postal_code: "",
        city: "",
        remarks: "",
        contact_info: {
          name: "",
          first_name: "",
          email: "",
          phone: "",
        },
      };
      this.$refs.form.reset();
    },
  },
};
</script>
