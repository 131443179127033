<template>
  <v-app id="app">
    <v-app-bar app color="rgba(189,16,32)">
      <v-spacer></v-spacer>
      <v-btn text to="/order">Bestellungen</v-btn>
      <v-btn text to="/address-mutation">Adressänderung Eigentümer</v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import '~vuetify/dist/vuetify.min.css';
@import "../styles/base.scss";
</style>